import Accordion from 'react-bootstrap/Accordion';

import * as React from "react";
import {ContractType} from "../../type-def";
//@ts-ignore
import SignatureCanvas from 'react-signature-canvas'
import {SignContract} from "./index";
import {Link} from "react-router-dom";

export default function EditSignature(contract: ContractType) {
    return (
        <Accordion.Item eventKey="4">

            <Accordion.Header>
                <strong className="text-uppercase">Signature du client</strong>
            </Accordion.Header>

            <Accordion.Body>
                <div className='text-center mb-4 py-2s'>

                    <h6 className='text-uppercase mb-2'>Signature du contrat de transport</h6>

                    <img src='/signature.png' width={120} />

                    {! contract.signature_date && (
                        <div>Cliquer sur le bouton ci-dessous pour permettre au client de signer son contrat</div>
                    )}


                    {contract.signature_date && (
                        <div className='text-success fw-bold'>Le contrat a été signé le {contract.signature_date_text}</div>
                    )}

                </div>

                {! contract.signature_date && (
                    <SignContract {...contract} />
                )}

            </Accordion.Body>
        </Accordion.Item>
    );
}
