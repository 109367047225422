import {SolvencyType} from "../../type-def";

export default function SolvencyStatus(solvency: SolvencyType) {

    if (! solvency.number) {
        return null;
    }

    const payed = parseInt(String(solvency.payed)) === 1

    return (
        <div className='text-center p-4 bg-light my-2 border rounded'>

            <img src={`/${payed ? '' : 'not-'}paid.png`} width={100} alt='Image status' />

            <h3 className={`text-${payed ? 'success' : 'danger'} text-uppercase my-4`}>
                  {payed ? null: 'Non'} réglé
            </h3>
        </div>
    )
}
