import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faTrash} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useState} from "react";
import {Loader, VerticalCenteredModal} from "../../components";
import {ContractType, PackageType} from "../../type-def";
import {useContract} from "../../contexts/contractContext";

export default function DeletePackage({contract, data}: {contract: ContractType, data: PackageType}) {

    const [modalShow, setModalShow] = useState<boolean>(false);
    const {deletingPackage, deletePackage} = useContract()

    useEffect(() => {

    }, [modalShow])

    const handleDelete = () => {

        deletePackage(contract, {
            id: data.id
        }, data => {
            if (contract.onPackageDeleted) {
                contract.onPackageDeleted(data)
            }
        })
    }

    const handleInit = () => {
        setModalShow(true)
    }

    return <>
        <button type='button' className='btn shadow btn-outline-danger' onClick={handleInit}>
            {/*@ts-ignore*/}
            <FontAwesomeIcon className="fa-fw" icon={faTrash} />
        </button>

        <VerticalCenteredModal
            title={`Suppression du colis ${data.designation}`}
            show={modalShow}
            onHide={() => setModalShow(false)}
        >
            <div className='d-flex justify-content-end mt-2'>
                {deletingPackage && <Loader />}

                {!deletingPackage && (
                    <button type='button' className='btn btn-danger btn-lg' onClick={handleDelete}>
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className="fa-fw" icon={faSave} /> Supprimer
                    </button>
                )}
            </div>
        </VerticalCenteredModal>
    </>
}
