import React, {useState} from "react"
import {Required} from "../index";


type InputTextType = {
    label: string | undefined;
    text: string | undefined;
    explain?: string;
    required?: boolean;
    uid: string | undefined;
    onChanged?: (text: string) => void
    data?: Array<any>
}

export default function Select({label, text, explain, onChanged, uid, data = [], required = false}: InputTextType) {

    const [selectedChange, setSelectedChange] = useState<string | any>(text)

    const handleChanged = (event: any) => {

        const {value} = event.target

        setSelectedChange(value)

        if (onChanged) {
            onChanged(value)
        }
    }



    return (
        <div className='form-group my-2'>
            <label htmlFor={uid} className='mb-2 text-uppercase small'>
                {label} {required && <Required />}
            </label>
            <select id={uid} name={uid} value={selectedChange} onChange={handleChanged} className='form-control form-select py-2'>
                <option value=''>{''}</option>
                {data?.map((item: {id: string, title: string}) => (
                    <option key={item.id} value={item.id}>
                        {item.title}
                    </option>
                ))}
            </select>
            <div className='text-muted small'>{explain}</div>
        </div>
    )
}
