import * as React from "react";

type AlertType = {
    message?: string;
    type: 'success' | 'danger' | 'warning'
}
export default function Alert({message, type}: AlertType) {

    if (message?.length == 0) {
        return null
    }

    return (
        <div className={`alert alert-${type}`}>
            {message}
        </div>
    )
}
