
const _labels: any = {
    fr: {
       welcome: "Annoncez votre présence",
       cntracts: {

       },

    },

    en: {
        welcome: "Annoncez votre présence (en)",
    }
}

export default _labels
