import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCreditCard, faSave} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {Alert, Input, Loader, Select, VerticalCenteredModal} from "../../components";
import {ContractType} from "../../type-def";
import {useContract} from "../../contexts/contractContext";
import {useApp} from "../../contexts/appContext";

type InputValueType = 'mode_id' | 'amount' | 'designation' | 'description' | 'to_be_sent_on_date'

export default function ContractPaymentForm(contract: ContractType) {

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [values, setValues] = useState<any>({
        mode_id: '',
        amount: '',
        designation: '',
        description: '',
        to_be_sent_on_date: '',
    });

    const { paying, pay, messageSuccess, messageError, paymentModes, refresh} = useContract()

    const { user } = useApp()

    const handleValue = (target: InputValueType, value: string) => {
        setValues({
            ...values,
            [target]: value
        })
    }

    useEffect(() => {

    }, [modalShow])

    const handlePay = () => {

        pay(contract, {
            ...values,
            created_by: user.id
        }, data => {

            setModalShow(false)

            if (contract.onPaymentAdded) {
                contract.onPaymentAdded(data)
                //console.log('contract.onSigned', data)
            }

            setTimeout(() => {
                refresh()
            }, 300)
        })
    }

    const handleInit = () => {
        setModalShow(true)
    }

    //console.log('contract.onPaid', values)

    return <div className='my-4'>
        <p className='text-muted'>Ajouter un nouvel élément de facturation au contrat N° { contract.number }</p>

        <button type='button' disabled={paying} className='btn btn-lg shadow btn-danger rounded-5 w-100' onClick={handleInit}>
            {/*@ts-ignore*/}
            <FontAwesomeIcon className="fa-fw" icon={faCreditCard} />
            <span className='px-2 fw-bold'>Nouvelle facture</span>
        </button>

        <VerticalCenteredModal
            title="Nouvelle encaissement"
            show={modalShow}
            onHide={() => setModalShow(false)}
        >
            <Alert message={messageError} type='danger' />

            <Alert message={messageSuccess} type='success' />

            <Input
                label="Libellé"
                type='text'
                uid='designation'
                value={values['designation']}
                required
                onChanged={text => handleValue('designation', text)}
            />

            <Input
                label="Montant"
                type='number'
                uid='amount'
                required
                value={values['amount']}
                onChanged={text => handleValue('amount', text)}
            />

            <Input
                label="Date d'envoie au client"
                type='date'
                uid='to_be_sent_on_date'
                required
                value={values['to_be_sent_on_date']}
                onChanged={text => handleValue('to_be_sent_on_date', text)}
            />

            <Select
                label='Mode de paiement'
                text={values['mode_id']}
                required
                data={[
                    {id: 1, title: 'Espèces'},
                    {id: 2, title: 'Chèque'},
                    {id: 3, title: 'Virement bancaire'},
                ]}
                uid='mode_id'
                onChanged={text => handleValue('mode_id', text)}
            />

            <div className='d-flex justify-content-end mt-2'>
                {paying && <Loader />}
                {!paying && (
                    <button type='button' className='btn btn-success btn-lg' onClick={handlePay}>
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className="fa-fw" icon={faSave} /> Valider
                    </button>
                )}
            </div>
        </VerticalCenteredModal>
    </div>
}
