import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlus} from "@fortawesome/free-solid-svg-icons";
import React from "react";
import {Loader} from "../../components";
import {useContract} from "../../contexts/contractContext";

export default function NewContract() {

    const {loading, store } = useContract();

    return <div className='w-100 d-flex justify-content-end mb-4'>
        {! loading && (
            <button disabled={loading} className='btn shadow btn-danger rounded-5' onClick={store}>
                {/*@ts-ignore*/}
                <FontAwesomeIcon className="fa-fw" icon={faPlus} />
                <span className='px-2 fw-bold'>Nouveau contrat</span>
            </button>
        )}
        <Loader show={loading} />
    </div>
}
