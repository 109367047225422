import Accordion from 'react-bootstrap/Accordion';
import {Alert, Input, Loader, Select, VerticalCenteredModal} from "../../components";
import * as React from "react";
import {useEffect, useState} from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave, faBox} from "@fortawesome/free-solid-svg-icons";
import {ContractType, PackageType} from "../../type-def";
// @ts-ignore
import {_get, } from "../../api";
import {DeletePackage} from "./index";
import {useContract} from "../../contexts/contractContext";

const initialValues = {
    type_id: '',
    designation: '',
}

export default function EditPackages(contract: ContractType) {

    const [loading, isLoading] = useState<boolean>(true);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [types, setTypes] = useState<any[]>([]);

    const [values, setValues] = useState<any>(initialValues);

    const { addPackage, addingPackage} = useContract()

    const handleValue = (target: 'type_id' | 'designation', value: string) => {
        setValues({
            ...values,
            [target]: value
        })
    }

    const handleSubmit = async () => {
        const {type_id, designation} = values

        if (type_id && designation) {

           const  response = addPackage(contract, {category_id: type_id, designation}, contractItem => {

               setValues(initialValues)

               if (contract.onPackageAdded) {

                   contract.onPackageAdded(contractItem)
               }

               setModalShow(false)

            })
        }

        console.log('_values', values)
    }

    const controller = new AbortController

    useEffect(() => {
        _get('categories', controller)
            .then(response => setTypes(response.data.data))
            .finally(() => isLoading(false))
    }, [])

    if (loading) {
        return <Loader />
    }

    return (
        <Accordion.Item eventKey="2">
            <Accordion.Header>
                <strong className='text-uppercase fw-bold'>
                   Les colis ({contract?.packages?.length})
                </strong>
            </Accordion.Header>
            <Accordion.Body>

                {contract.signature_date && (
                    <Alert message="/!\  Un contrat signé ne peut plus être modifié" type='warning' />
                )}

                {! contract.signature_date && (
                    <div className='d-flex justify-content-end mb-2'>
                        <button type='button' className='btn btn-warning shadow' onClick={ () => setModalShow(true) }>
                            {/*@ts-ignore*/}
                            <FontAwesomeIcon className="fa-fw" icon={faBox} /> Nouveau colis
                        </button>
                    </div>
                )}

                <VerticalCenteredModal
                    title="Nouveau colis"
                    show={modalShow}
                    onHide={() => setModalShow(false)}
                >
                    <Select
                        label='Type de colis'
                        text={values['type_id']}
                        data={types}
                        uid="type_id"
                        onChanged={text => handleValue('type_id', text)}
                    />

                    <Input
                        label="Désignation"
                        uid={``}
                        required
                        value={values['designation']}
                        onChanged={text => handleValue('designation', text)}
                    />

                    <div className='d-flex justify-content-end'>
                        {addingPackage && <Loader />}
                        {!addingPackage && (
                            <button type='button' className='btn btn-success btn-lg' onClick={handleSubmit}>
                                {/*@ts-ignore*/}
                                <FontAwesomeIcon className="fa-fw" icon={faSave} /> Valider
                            </button>
                        )}
                    </div>
                </VerticalCenteredModal>

                <ListRow contract={contract} list={contract.packages} />

                <hr />

                <TypeInfo size={types.length} />

            </Accordion.Body>
        </Accordion.Item>
    );
}

function TypeInfo({size}: {size: number}) {
    return <>
        <div className='small text-right'>
            Types de colis : {size}
        </div>
    </>
}

function ListRow({list, contract}: {list: PackageType[], contract: ContractType}) {

    if (list === undefined) {
        return null;
    }

    return <>
        {list.length > 0 && (
            <ul className='list-group list-group-flush'>
                {list.map(item => (
                    <li key={item.id} className='list-group-item'>
                        <ListItem contract={contract} item={item} />
                    </li>
                ))}
            </ul>
        )}
    </>
}

function ListItem({contract, item}: { contract: ContractType, item: PackageType }) {
    return <div className='d-flex justify-content-between'>

        <div className='w-100'>
            {item.order} - {item.designation} <br />
            <small className='text-muted fw-bold'> #{item.number}</small>
        </div>

        {! contract.signature_date && (
            <div className='w-50 d-flex justify-content-end'>
                <DeletePackage contract={contract} data={item} />
            </div>
        )}
    </div>
}
