import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLink, faSave} from "@fortawesome/free-solid-svg-icons";
import React, {useEffect, useRef, useState} from "react";
import {Alert, Input, Loader, Select, VerticalCenteredModal} from "../../components";
import {ContractType} from "../../type-def";
import {useContract} from "../../contexts/contractContext";
import {useApp} from "../../contexts/appContext";
//@ts-ignore
import SignatureCanvas from 'react-signature-canvas'


export default function SignContract(contract: ContractType) {

    const canvas = useRef(null)
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [values, setValues] = useState<any>({
        signature_date: contract.signature_date,
        signature: contract.signature,
    });

    const { sign, signing, messageSuccess, messageError} = useContract()
    const { user } = useApp()

    const handleValue = (target: 'signature' | 'signature_date', value: string) => {
        setValues({
            ...values,
            [target]: value
        })
    }

    const onEnd = () => {

        if (canvas.current) {
            // @ts-ignore
            handleValue('signature', canvas.current?.getTrimmedCanvas()?.toDataURL('image/png'))
        }
    }

    const clear = () => {
        if (canvas.current) {
            // @ts-ignore
            canvas.current?.clear()

            handleValue('signature', '')
        }
    }

    const init = () => {
        if (canvas.current) {
            // @ts-ignore
            canvas.current?.fromDataURL(values['signature'])
        }
    }

    useEffect(() => {
        init()
    }, [modalShow])

    const handleSign = () => {
        sign(contract, {
            ...values,
            signed_by: user.id
        }, data => {

            setModalShow(false)

            if (contract.onSigned) {
                contract.onSigned(data)
                //console.log('contract.onSigned', data)
            }
        })
    }

    const handleInit = () => {
        init()
        setModalShow(true)
    }

    console.log('contract.onSigned', values)

    return <>
        <button type='button' disabled={signing} className='btn btn-lg shadow btn-danger rounded-5 w-100' onClick={handleInit}>
            {/*@ts-ignore*/}
            <FontAwesomeIcon className="fa-fw" icon={faLink} />
            <span className='px-2 fw-bold'>Signer le contrat</span>
        </button>

        <VerticalCenteredModal
            title="Signature du contrat"
            show={modalShow}
            onHide={() => setModalShow(false)}
        >
            <Alert message={messageError} type='danger' />

            <Alert message={messageSuccess} type='success' />

            <Input
                label="Date de signature"
                type='date'
                uid={``}
                required
                value={values['signature_date']}
                onChanged={text => handleValue('signature_date', text)}
            />

            <div className='d-flex justify-content-center py-4'>
                <div>
                    <h6 className='text-uppercase small'>Signature du client</h6>
                    <SignatureCanvas
                        ref={(ref: any) => { canvas.current = ref }}
                        onEnd={onEnd}
                        canvasProps={{
                            height: 200,
                            width: 350,
                            className: 'bg-orange'
                        }}
                    />
                </div>
            </div>

            <button type='button' className='btn btn-lg w-100 btn-outline-dark small' onClick={clear}>
                Effacer la signature
            </button>

            <div className='d-flex justify-content-end mt-2'>
                {signing && <Loader />}
                {!signing && (
                    <button type='button' className='btn btn-danger btn-lg' onClick={handleSign}>
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className="fa-fw" icon={faSave} /> Signer
                    </button>
                )}
            </div>
        </VerticalCenteredModal>
    </>
}
