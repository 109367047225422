import {AppLayout} from "../layouts"
import {Welcome} from "../components";
import {useApp} from "../contexts/appContext";
import * as React from "react";
import {ContractList, NewContract} from "../domains/contracts";
import {OrderList} from "../domains/orders";
import OrderProvider from "../contexts/orderContext";
import {VerifySearchForm} from "../domains/verify";

export default function AppScreen() {

    const {user, can} = useApp()
    return (
        <AppLayout>
            <div className='m-1'>
                <Welcome {...user} />
            </div>

            {can('manage contracts') && (
                <>
                    <NewContract />
                    <ContractList />
                    <hr />
                </>
            )}

            {can('manage transport orders') && (
                <OrderProvider>
                    <OrderList />
                    <hr />
                </OrderProvider>
            )}

            {can('check customer solvency') && (
                <>
                    <VerifySearchForm />
                </>
            )}
        </AppLayout>
    );
}
