import React from "react";
import {ContractType, OrderType} from "../../type-def";
import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEdit, faHandshake} from "@fortawesome/free-solid-svg-icons";
import {EmptyList, Loader} from "../../components";
import {useOrder} from "../../contexts/orderContext";

export default function OrderList() {

    const {list, loading} = useOrder();

    if (loading) {
        return <Loader />
    }

    return <div>
        <h6 className='text-uppercase'>
            Les OT programmés: {list.length}
        </h6>

        {list.length === 0 && <EmptyList url='/order.png' />}

        <ul className='list-group list-group-flush'>
            {list.map((item: OrderType) => (
                <li className='list-group-item list-group-item-primar' key={item.id}>
                    <ListItem  {...item} />
                </li>
            ))}
        </ul>
    </div>
}

function ListItem(order: OrderType) {
    return (
        <div>
            <Link  to={`/orders/${order.slug}`}>
                <div className='d-flex justify-content-between'>
                    <h6 className='fs-bold p-0 w-100'>{order.number} | {order.designation}</h6>
                    <div className='w-25 d-flex justify-content-end text-muted'>
                        {/*@ts-ignore*/}
                        <FontAwesomeIcon className="fa-fw text-muted" icon={faEdit} />
                    </div>
                </div>
                <div className='text-muted'>
                    #{order.id} - {order.departure_city} - {order.departure_city}
                </div>
            </Link>
        </div>
    )
}
