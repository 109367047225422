import * as React from 'react'
import {Routes, Route, useLocation, Navigate,} from 'react-router-dom'
import AppProvider, {useApp} from './contexts/appContext'
import {AppScreen, OrderScreen, AuthScreen, EditContractScreen, PaymentContractScreen} from './screens'
import ContractProvider from "./contexts/contractContext";

export default function App() {

  return (
      <AppProvider>
          <ContractProvider>
              <Routes>
                  <Route path="/auth" element={<AuthScreen />} />
                  <Route path="/" element={<Private><AppScreen /></Private>} />
                  <Route path="/contracts/:slug" element={<Private><EditContractScreen /></Private>} />
                  <Route path="/contracts/:slug/payments" element={<Private><PaymentContractScreen /></Private>} />
                  <Route path="/orders/:slug" element={<Private><OrderScreen /></Private>} />
              </Routes>
          </ContractProvider>
      </AppProvider>
  );
}

function Private({ children }: { children: JSX.Element }) {

    let auth = useApp();

    let location = useLocation();

    if (! auth.logged) {
        // Redirect them to the /login page, but save the current location they were
        // trying to go to when they were redirected. This allows us to send them
        // along to that page after they login, which is a nicer user experience
        // than dropping them off on the home page.
        return <Navigate to="/auth" state={{ from: location }} replace />;
    }

    return children;
}

