import {_get, _post} from "../api";
import {useState, createContext, useEffect, useContext} from "react";
import {ContractContextType, ContractType, PaymentModeType} from "../type-def";

const ContractContext = createContext<ContractContextType>({
    list: [],
    contract: undefined,
    loading: false,
    created: false,
    store: () => Promise<any>,
    refresh: () => Promise<any>,
    signing: false,
    sign: (contract: ContractType, data: any, onSigned: (data: ContractType) => any) => Promise<any>,
    addPackage: (contract: ContractType, data: any, callback: (contract: ContractType) => void) => Promise<any>,
    deletePackage: (contract: ContractType, data: any, callback: (contract: ContractType) => void) => Promise<any>,
    addingPackage: false,
    deletingPackage: false,
    messageError: '',
    messageSuccess: '',
    pay: (contract: ContractType, data: any, callback: (contract: ContractType) => void) => Promise<any>,
    paying: false,
    paymentModes: [],
});

export default function ContractProvider({ children }: { children: React.ReactNode }) {

    const [loading, isLoading] = useState<boolean>(false);
    const [addingPackage, isAddingPackage] = useState<boolean>(false);
    const [deletingPackage, isDeletingPackage] = useState<boolean>(false);
    const [created, isCreated] = useState<boolean>(false)
    const [messageError, setMessageError] = useState<string>('')
    const [messageSuccess, setMessageSuccess] = useState<string>('')
    const [signing, isSigning] = useState<boolean>(false)
    const [paying, isPaying] = useState<boolean>(false)
    const [list, setList] = useState<ContractType[]>([]);
    const [paymentModes, setPaymentModes] = useState<PaymentModeType[]>([]);

    const [contract, setContract] = useState<ContractType>();
    const controller = new AbortController

    const fetchContracts = () => {

        isLoading(true)

        return _get('/contracts', controller)
            .then(response => {

                const list = response.data.data

                setList(list)

            })
            .catch(error => {})
            .finally(() => isLoading(false))

    }

    const refresh = () => fetchContracts()

    const store = () => {

        isLoading(true)

        isCreated(false)

        _post('/contracts/store', {}, controller)
            .then(response => {
                const data = response.data.data

                if (data) {
                    setTimeout(() => {

                        setContract(data)

                        setList(prevState => [data, ...prevState])

                        isCreated(true)
                    }, 500)

                }
                console.log('_data', data)
            })
            .finally(() => setTimeout(() => isLoading(false), 600))
    }

    const sign = (contract: ContractType, data: any, onSigned: (data: ContractType) => any) => {

        isSigning(true)

        setMessageSuccess('')

        setMessageError('')

        _post(`/contracts/${contract.slug}/sign`, {...data}, controller)
            .then(response => {

                const data = response.data.data

                if (data) {

                    setTimeout(() => {

                        setContract(data)

                        setList(prevState => [data, ...prevState.filter(item => item.id !== data.id)])

                        onSigned(data)

                    }, 500)
                }

                if (! response.data.error) {
                    setMessageSuccess(response.data.message)
                }

            })
            .catch(error => {
                setMessageError(error.response.data.message)
            })
            .finally(() => setTimeout(() => isSigning(false), 600))
    }

    const addPackage = (contract: ContractType, data: any, callback: (contract: ContractType) => void) => {

        isAddingPackage(true)

        _post(`/contracts/${contract.slug}/add-package`, data, controller)
            .then(response => {

                const data = response.data.data

                if (data) {
                    setTimeout(() => {

                        setContract(data)

                        setList(prevState => [data, ...prevState.filter(item => item.id !== data.id)])

                        callback(data)

                    }, 500)
                }

            })
            .finally(() => setTimeout(() => isAddingPackage(false), 600))
    }


    const deletePackage = (contract: ContractType, data: any, callback: (contract: ContractType) => void) => {

        isDeletingPackage(true)

        _post(`/contracts/${contract.slug}/delete-package`, data, controller)
            .then(response => {

                const data = response.data.data

                if (data) {
                    setTimeout(() => {

                        setContract(data)

                        setList(prevState => [data, ...prevState.filter(item => item.id !== data.id)])

                        callback(data)

                    }, 500)
                }

            })
            .finally(() => setTimeout(() => isDeletingPackage(false), 600))
    }

    const pay = (contract: ContractType, data: any, callback: (contract: ContractType) => void) => {

        isPaying(true)
        setMessageSuccess('')
        setMessageError('')

        _post(`/payments/store?contract_id=${contract.id}&created_by=${data['created_by']}&price=${contract.price}`, data, controller)
            .then(response => {

                const data = response.data.data

                if (data) {
                    setTimeout(() => {
                        callback(data)
                    }, 500)
                }

                if (! response.data.error) {
                    setMessageSuccess(response.data.message)
                }

            })
            .catch(error => {
                setMessageError(error.response.data.message)
            })
            .finally(() => setTimeout(() => isPaying(false), 600))
    }


    useEffect(() => {

        fetchContracts()

        return () => controller.abort()
    }, [])

    const value = {
        list,
        contract,
        loading,
        created,
        signing,
        store,
        sign,
        addPackage,
        deletePackage,
        addingPackage,
        messageError,
        messageSuccess,
        deletingPackage,
        paying,
        pay,
        paymentModes,
        refresh
    };

    return (
        <ContractContext.Provider value={value}>
            {children}
        </ContractContext.Provider>
    );
}

export function useContract() {
    return useContext(ContractContext);
}

