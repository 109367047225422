import * as React from "react";

type HeaderType = {
    title?: string;
    setOpenQr: (open: boolean) => void
    openQr: boolean
}
export default function Header({title, setOpenQr, openQr}: HeaderType) {
    return (
        <header className='p-2'>
            <div className='d-flex justify-content-between'>
                <h2 className='w-100'>{title}</h2>
                <div className='w-50 d-flex justify-content-end'>
                    <button className='btn btn-outline-dark btn-sm text-uppercase small' onClick={() => setOpenQr(!openQr)}>
                        {openQr ? "Close" : "Open"} Scanner
                    </button>
                </div>
            </div>
        </header>
    )
}
