import Accordion from 'react-bootstrap/Accordion';
import {Input} from "../../components";
import * as React from "react";
import {ContractType} from "../../type-def";

export default function EditSender(contract: ContractType) {
    return (
        <Accordion.Item eventKey="0">
            <Accordion.Header>
                <strong className="text-uppercase">L'expéditeur</strong>
            </Accordion.Header>

            <Accordion.Body>
                <Input label="Nom complet" uid='sender_name' value={contract.sender_name} required />

                <div className='row'>
                    <div className='col-6'>
                        <Input type='tel' label="Téléphone" uid='sender_phone' value={contract.sender_phone} required />
                    </div>
                    <div className='col-6'>
                        <Input type='email' label="Email" uid='sender_email' value={contract.sender_email} required />
                    </div>
                </div>

                <Input label="N° CNI / PASSEPORT" uid='sender_cni' value={contract.sender_cni} />

                <Input label="Adresse" uid='departure_address' value={contract.departure_address} required  />

                <div className='row'>
                    <div className='col-6'>
                        <Input type='number' label="Code postal" uid='departure_zip_code' value={contract.departure_zip_code} />
                    </div>
                    <div className='col-6'>
                        <Input label="Ville" uid='departure_city' value={contract.departure_city} required />
                    </div>
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
}
