import React, {useState} from "react";
import {EmptyList} from "../../components";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQrcode} from "@fortawesome/free-solid-svg-icons";

type PackageListType = {
    list: any[]
}
export default function PackageList({list}: PackageListType) {

    return <div>
        <h6 className='text-uppercase'>
            Les étiquettes programmés: {list.length}
        </h6>

        {list.length === 0 && <EmptyList url='/order.png' />}

        <ul className='list-group list-group-flush'>
            {list.map((item: string) => (
                <li className='list-group-item list-group-item-primar' key={item}>
                    <ListItem item={item} />
                </li>
            ))}
        </ul>
    </div>
}

function ListItem({item}: {item: string}) {
    const [checked, setChecked] = useState<boolean>(true)

    return (
        <div className='d-flex align-items-center'>
            <div className='px-2'>
                {/*@ts-ignore*/}
                <FontAwesomeIcon className="fa-fw text-muted" icon={faQrcode} />
            </div>
            <label className='fw-bold px-2' htmlFor={`number--${item}`}>
                {item}
            </label>
            <div className='w-100 d-flex justify-content-end'>
                <input
                    checked={checked}
                    id={`number--${item}`}
                    type='checkbox'
                    name={`number--${item}`}
                    value={item}
                    onChange={(event) => setChecked(event.target.checked)}
                />
            </div>
        </div>
    )
}
