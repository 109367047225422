import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import React from "react";

export default function Loader({show = true}: {show?: boolean}) {

    if (! show) {
        return <span />;
    }

    return <div className='text-center py-2'>
        {/*@ts-ignore*/}
        <FontAwesomeIcon className={`fa-spin fa-2x`} icon={faSpinner} />
    </div>
}
