import * as React from "react";
import {SimpleLayout} from "../layouts";
import {useParams} from "react-router-dom";
import {_get} from "../api";
import {useEffect, useState} from "react";
import {Loader, QrReader, Unauthorised} from "../components";
import {OrderType} from "../type-def";
import {Header, EditForm} from "../domains/orders";
import OrderProvider, {useOrder} from "../contexts/orderContext";
import {useApp} from "../contexts/appContext";


export default function OrderScreen() {

    const [process, isLoading] = useState<boolean>(false);
    const [order, setOrder] = useState<OrderType>();
    const [openQr, setOpenQr] = useState<boolean>(false);
    const [packages, setPackages] = useState<string[]>([]);

    const controller = new AbortController
    const {slug} = useParams();
    const {can} = useApp();
    const fetchData = () => {

        isLoading(true)

        _get(`/orders/${slug}`)
            .then(response => {
                const {data} = response.data

                setOrder(data)
                setPackages(data?.packages?.map((item: any) => item.number) ?? [])
                setOpenQr(true)

            }).finally(() => isLoading(false))
    }

    const onScanResult = (data: string[]) => {
        setPackages(prevState => Array.from(new Set([...data, ...prevState])).filter(item => item !== undefined))
    }

    const packageAdded = (data: OrderType) => {
      fetchData()
    }

    useEffect(() => {

        fetchData();

        return () => controller.abort()

    }, [])

    useEffect(() => {

    }, [packages])

    if (! can('manage transport orders')) {
        return <Unauthorised />
    }

    if (process) {
        return <Loader />
    }


    return (
        <SimpleLayout goBack={'/'}>
            <Header title={order?.designation} setOpenQr={setOpenQr} openQr={openQr} />

            {openQr && <>
                <QrReader onResult={onScanResult} />
                <div className='scanResult'>{packages.length}</div>
            </>}

            {! openQr && order && (
                <OrderProvider>
                    <EditForm packages={packages} order={order} packageAdded={packageAdded} />
                </OrderProvider>
            )}
        </SimpleLayout>
    );
}
