import axios from "axios"

//axios.defaults.headers.common['Content-Type'] = "application/x-www-form-urlencoded"

// Point d'entrée de Web Service
//const URL = 'http://diaspo-fret.test/api'
const URL = 'https://diaspo-fret.octet-group.org/api'

const axiosInstance = axios.create({
    baseURL: URL,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
    },
    timeout: 10000, // temps limite pour les requêtes (en ms)
});

// Gestion des requêtes et des réponses
axiosInstance.interceptors.request.use(
    config => {
        // Ajoute des configurations supplémentaires si nécessaire
        // Par exemple, ajouter un token d'authentification
        const token = window.localStorage.getItem('com.diaspo-fret.token');
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }

        //const fullUrl = `${config.baseURL}${config.url}`;

        console.log('axiosInstance TOKEN:', token);

        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

/*axiosInstance.interceptors.response.use(
    response => {
        // Gère les réponses avec succès
        return response;
    },
    error => {
        // Gère les erreurs de réponse
        if (error.response) {
            // Le serveur a répondu avec un statut différent de 2xx
            console.error('Error Response:', error.response);
        } else if (error.request) {
            // La requête a été envoyée mais aucune réponse n'a été reçue
            console.error('Error Request:', error.request);
        } else {
            // Quelque chose s'est passé lors de la configuration de la requête
            console.error('Error Message:', error.message);
        }

        return Promise.reject(error);
    }
);*/


function _get(path, controller) {
    return axiosInstance.get(path, {signal: controller?.signal})
}

function _post(path, data, controller) {
    return axiosInstance.post(path, {...data},{})
}

export {
    _get,
    _post
}
