import * as React from "react";
import {Link} from "react-router-dom";

export default function AppLogo() {
    return (
        <div className="dropdown">
            <Link to={'/'} className="d-flex align-items-center col-lg-4 mb-2 mb-lg-0 link-body-emphasis text-decoration-none">
                <img style={{ width: 30 }} src="/logo.png" alt="Logo App Name" />
            </Link>
        </div>
    )
}
