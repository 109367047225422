import {OrderType} from "../../type-def";
import {useOrder} from "../../contexts/orderContext";
import {Alert, EmptyList, Loader} from "../../components";
import {PackageList} from "./index";
import * as React from "react";

type EditFormType = {
    packages: any[];
    order: OrderType;
    packageAdded: any
}
export default function EditForm({packages,  order, packageAdded}: EditFormType) {

    const {loading, store, success, error} = useOrder();
    const handleSubmit = (e: React.FormEvent) => {

        e.preventDefault();

        // @ts-ignore
        const forms = e.target.elements
        let formData = {}
        let values = []

        for (let i = 0; i < forms.length; i++) {

            let element = forms[i];

            const elementName = element.name
            const value = element.value

            if(elementName && element.type === 'checkbox' && element.checked) {
                // @ts-ignore
                //formData[elementName] = value
                values.push(value)

                //console.log('element', element.checked)
            }
        }

        // @ts-ignore
        formData['numbers'] = JSON.stringify(values);
        // @ts-ignore
        formData['slug'] = order?.slug;

        store(formData, (data: any) => {
            if (packageAdded) {
                //alert(true)
                packageAdded(data)
            }
        })
    }

    if (packages === undefined || packages.length === 0) {
        return <EmptyList url='/order.png' />;
    }

    console.log('success', success)

    if (loading) {
        return <Loader />
    }

    return (
        <form className='container' onSubmit={handleSubmit}>

            <Alert message={success} type='success' />

            <Alert message={error} type='danger' />

            <PackageList list={packages} />

            {packages.length > 0 && (
                <div className='form-group'>
                    <button className='btn btn-dark btn-lg w-100 shadow rounded'>
                        Enregistrer
                    </button>
                </div>
            )}
        </form>
    )
}
