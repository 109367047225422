import Accordion from 'react-bootstrap/Accordion';
import {Input} from "../../components";
import * as React from "react";
import {ContractType} from "../../type-def";

export default function EditReceiver(contract: ContractType) {
    return (
        <Accordion.Item eventKey="1">
            <Accordion.Header>
                <strong className="text-uppercase">Le destinataire</strong>
            </Accordion.Header>

            <Accordion.Body>
                <Input label="Nom complet" uid='receiver_name' value={contract.receiver_name} required />
                <Input type='tel' label="Téléphone" uid='receiver_phone' value={contract.receiver_phone} required />
                <Input label="Ville" uid='receiver_city' value={contract.receiver_city} required />
            </Accordion.Body>
        </Accordion.Item>
    );
}
