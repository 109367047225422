import React, {useState} from "react"
import {Required} from "../index";

type InputTextType = {
    label: string;
    value?: string;
    uid: string;
    type?: string;
    explain?: string;
    placeholder?: string;
    required?: boolean;
    onChanged?: (text: string) => void
}

export default function Input({label, value, explain, onChanged, uid, placeholder, type = 'text', required = false}: InputTextType) {
    const [text, setValue] = useState<string | undefined>(value ?? '')

    const handleChanged = (inputText: string) => {

        setValue(inputText)

        if (onChanged) {
            onChanged(inputText)
        }

    }
    return (
        <div className='form-group my-2'>
            <label htmlFor={uid} className='mb-2 small text-uppercase'>
                {label}
                {required && <Required />}
            </label>
            <input
                type={type}
                id={uid}
                name={uid}
                className="form-control text-right bg-light py-2"
                placeholder={placeholder}
                onChange={event => handleChanged(event.target.value)}
                value={text}
                min={1}
            />
            <div className='text-muted small'>{explain}</div>
        </div>
    )
}
