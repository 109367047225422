import * as React from "react"
import AppLogo from "./navbars/AppLogo";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPowerOff} from "@fortawesome/free-solid-svg-icons";
import {useApp} from "../contexts/appContext";

export default function Header() {

    const {logout} = useApp()

    return (
        <header>
            <nav className="py-2 border-bottom bg-white shadow-sm fixed-top">
                <div className="container d-grid gap-3 align-items-center" style={{gridTemplateColumns: "1fr 8fr"}}>
                    <AppLogo />

                    <div className="d-flex align-items-center">

                        <div className="w-100 me-3">
                            <div className="d-none d-md-block">
                                {/*<Search />*/}
                            </div>
                        </div>
                        <div className="flex-shrink-0 dropdown">
                            <a onClick={logout} href="#" className="d-block link-body-emphasis text-decoration-none text-danger">
                                {/*@ts-ignore*/}
                                <FontAwesomeIcon className="fa-fw fa-2x" icon={faPowerOff} />
                            </a>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    )
}
