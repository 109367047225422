import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
export default function VerticalCenteredModal(props: any) {

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {props.title}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {props.children}
            </Modal.Body>
            {/*<Modal.Footer>
                <Button onClick={props.onHide}>Close</Button>
            </Modal.Footer>*/}
        </Modal>
    );
}
