import {AppLayout, SimpleLayout} from "../layouts"
import {useState, useEffect} from "react";
import {Loader, RequiredInfo, Unauthorised} from "../components";
import {_get, _post} from "../api";
import {EditSender, EditReceiver, EditPackages, EditSignature, SendSMS, EditPayment} from "../domains/contracts";
import {useParams} from "react-router-dom";
import {ContractType} from "../type-def";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSave} from "@fortawesome/free-solid-svg-icons";
import * as React from "react";
import {useContract} from "../contexts/contractContext";
import Accordion from 'react-bootstrap/Accordion';
import {useApp} from "../contexts/appContext";

export default function EditContractScreen() {

    const controller = new AbortController()
    const [loading, isLoading] = useState<boolean>(true)
    const [contract, setContract] = useState<ContractType>()
    const [error, setError] = useState<string>('')
    const [success, setSuccess] = useState<string>('')
    const {slug} = useParams();

    const {sign, signing, refresh} = useContract()
    const {can} = useApp();


    const fetchData = () => {

        isLoading(true)

        _get(`/contracts/${slug}`)
            .then(response => {
                const contract = response.data.data
                //console.log('_contract', contract)
                if (contract) {
                    setContract(contract)
                }

            }).finally(() => isLoading(false))
    }

    const resetContract = (contract: ContractType) => {
      setContract(contract);
      refresh();
    }

    useEffect(() => {
        fetchData()

        return () => controller.abort()
    }, [signing])

    const status = () => {
        if(success.length > 0) {
            return 'success'
        }

        if(error.length > 0) {
            return 'danger'
        }

        return ''
    }


    const _submit = async (e: any) => {

        e.preventDefault();

        isLoading(true)

        setError('')

        setSuccess('')

        const forms = e.target.elements
        let formData = {}

        for (let i = 0; i < forms.length; i++) {

            let element = forms[i];

            const elementName = element.name
            const value = element.value

            if(elementName) {
                // @ts-ignore
                formData[elementName] = value
            }

        }

        // console.log('_formData', formData)

        _post(`/contracts/${slug}`, formData, controller)
            .then(response => {
                const data = response.data

                if (! data.error) {

                    setSuccess(data.message)

                    refresh()

                    setContract(data.data)

                } else {
                    setError(data?.message)
                }

                //console.log('_contract', data)
            })
            .catch(error => {
                setError(error?.response?.data?.message)
                // console.log('_error', error?.response?.data?.message)
            })
            .finally(() => isLoading(false))
    }

    if (! can('manage contracts')) {
        return <Unauthorised />
    }

    return (
        <SimpleLayout goBack={'/'} title={contract?.title}>

            <div className='d-flex justify-content-end align-items-center'>
                {contract !== undefined && <SendSMS {...contract} />}
            </div>

            <RequiredInfo />

            <form onSubmit={_submit} className='pb-5'>

                <ShowMessage message={error} theme='danger' />
                <ShowMessage message={success} theme='success' />

                {contract !== undefined && (
                    <Accordion defaultActiveKey={['0']} alwaysOpen>

                        <EditSender {...contract} />

                        <EditReceiver {...contract} />

                        <EditPayment {...contract} onPaymentAdded={resetContract} />

                        <EditPackages
                            {...contract}
                            onPackageAdded={resetContract}
                            onPackageDeleted={resetContract}
                        />

                        {contract.can_be_signed && <EditSignature {...contract} onSigned={resetContract} />}

                    </Accordion>
                )}

                <div className={`fixed-bottom bg- p-2 alert alert-${status()} rounded-0`} style={{bottom: -15}}>
                    {! loading && (
                        <button className={`btn btn-dark w-100 btn-lg`}>
                            {/*@ts-ignore*/}
                            <FontAwesomeIcon className="fa-fw" icon={faSave} />
                            Enregistrer
                        </button>
                    )}

                    <Loader show={loading} />
                </div>
                <div style={{height: 40}}></div>
            </form>
        </SimpleLayout>
    );
}

function ShowMessage({ message, theme} : {message: string, theme: 'danger' | 'success'}) {

    if (! message) {
        return null
    }
    return <div className={`alert alert-${theme}`}>{message}</div>
}
