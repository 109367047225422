import {Outlet} from "react-router-dom"
import * as React from "react"

export default function GuestLayout({children}: any) {
    return (
        <div>
            <div className='p-4 ' style={{height: '100vh'}}>
                <div className='d-flex justify-content-center mb-4'>
                    <img style={{width: 70}} src="/logo.png" alt="Logo App Name" />
                </div>

                {children}
                <Outlet />
            </div>
        </div>
    );
}
