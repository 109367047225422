import {Link, Outlet} from "react-router-dom"
import * as React from "react"
import {Header, } from "./index"
import {useApp} from "../contexts/appContext"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronLeft} from "@fortawesome/free-solid-svg-icons";
import Button from 'react-bootstrap/Button';

type AppLayoutType = {
    children: React.ReactNode,
    goBack?: string
    title?: string
}

export default function SimpleLayout({goBack, title, children}: AppLayoutType) {

    return (
        <>
            <div className='vh-100'>
                {goBack && (
                    <div className='p-2 d-flex align-items-center border-bottom mb-4 bg-white shadow-sm fixed-top'>
                        <Link to={goBack}>
                            {/*@ts-ignore*/}
                            <FontAwesomeIcon className="fa-fw fa-2x" icon={faChevronLeft} />
                        </Link>
                        <span className='px-2 fw-bold text-uppercase'>{title}</span>
                    </div>
                )}
                <div style={{paddingTop: 65}} className='position-relative'>
                    {children}
                    <Outlet />
                </div>
            </div>
        </>
    );
}
