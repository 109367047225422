import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

export default function EmptyList({url}: {url?: string}) {

    return (
        <div className='text-center my-2 bg-light p-2 rounded'>
            {url && <img src={url} alt='Image Empty List' width={150} className='mb-2' />}

            <div className='text-muted'>Aucun élément trouvé</div>
        </div>
    )
}
