import Accordion from 'react-bootstrap/Accordion';
import {Input, Select} from "../../components";
import * as React from "react";
import {ContractType, PackageType, PaymentType} from "../../type-def";
import ContractPaymentForm from "./ContractPaymentForm";
import {DeletePackage} from "./index";

export default function EditPayment(contract: ContractType) {
    return (
        <Accordion.Item eventKey="3">
            <Accordion.Header>
                <strong className="text-uppercase">Prix du transport</strong>
            </Accordion.Header>

            <Accordion.Body>
                <div className='bg-success p-2'>
                    <Input label="Prix du transport (€)" uid='price' type='number' value={contract.price?.toString()} required />
                </div>
                <div className=''>
                    <Input label="Valeur des colis (€)" uid='package_value' type='number' value={contract.package_value?.toString()} />
                </div>
            </Accordion.Body>
        </Accordion.Item>
    );
}

function ListRow({list}: {list: PaymentType[]}) {
    return <div className='mt-3'>
        <hr />
        <h6 className='text-uppercase small'>Historiques des facturations</h6>
        {list.length > 0 && (
            <ul className='list-group list-group-flush'>
                {list.map(item => (
                    <li key={item.id} className='list-group-item'>
                        <ListItem  {...item} />
                    </li>
                ))}
            </ul>
        )}
    </div>
}

function ListItem(item: PaymentType) {
    return <div className='d-flex justify-content-between'>
        <div className='w-100'>
            {item.designation} <br/>
            <b className='text-success'>{item.amount}</b>
        </div>
    </div>
}
