import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faSearch, faUndo} from "@fortawesome/free-solid-svg-icons";
import React, {useState} from "react";
import {_get} from "../../api";
import {Alert, Loader} from "../../components";
import {SolvencyType} from "../../type-def";
import SolvencyStatus from "./SolvencyStatus";

export default function VerifySearchForm() {

    const [loading, isLoading] = useState<boolean>(false)
    const [number, setNumber] = useState<string>('')
    const [error, setError] = useState<string>('')
    const [solvency, setSolvency] = useState<SolvencyType>()

    const controller = new AbortController;

    const reset = () => {
      setSolvency(undefined);
      setNumber('')
    }

    const handleSubmit = (e: any) => {

      e.preventDefault();

      if (number?.length > 0) {

          isLoading(true);
          setError('');

          _get(`packages/info?number=${number}`, controller).then(response => {

              const {data} = response.data

              console.log('data', data);

              if (data && data?.number) {
                  setSolvency(data);
              } else {
                 setError("Aucun élément trouvé pour ce numéro")
              }

          }).catch(error => {
              console.log('error', error);
          }).finally(() => isLoading(false))
      }

    }

    if (loading) {
        return <Loader />
    }

    return (
        <form onSubmit={handleSubmit} className='pb-4'>
            <h6 className='text-uppercase'>Solvabilité d'un colis</h6>
            <p className='text-muted'>Renseigner un numéro de colis pour vérifier la solvabilité du client</p>

            <div className="input-group input-group-lg mb-2">
                <input
                    type="text"
                    name="number"
                    className="form-control text-uppercase"
                    placeholder="Ex P0156"
                    value={number}
                    onChange={(event) => setNumber(event.target.value)}
                />

                <button
                    className="btn btn-dark"
                >
                    {/*@ts-ignore*/}
                    <FontAwesomeIcon className='fa-2x' icon={faSearch} />
                </button>

                <button
                    className="btn btn-outline-dark"
                    type='button'
                    onClick={reset}
                >
                    {/*@ts-ignore*/}
                    <FontAwesomeIcon className='fa-2x' icon={faUndo} />
                </button>
            </div>

            {error.length > 0 && <Alert message={error} type='danger' />}

            {solvency !== undefined && error.length === 0 &&  <SolvencyStatus {...solvency} />}
        </form>
    )
}
