
import {_get, _post} from "../api";
import {useState, createContext, useEffect, useContext} from "react";
import {OrderContextType, OrderType} from "../type-def";

const OrderContext = createContext<OrderContextType>({} as OrderContextType);

export default function OrderProvider({ children }: { children: React.ReactNode }) {

    const [loading, isLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<string>('');
    const [list, setList] = useState<OrderType[]>([]);

    const controller = new AbortController

    const store = (data: any, callback: any) => {

        isLoading(true)

        setSuccess('')

        setError('')

        return _post('/orders/' + data['slug'] + '/add-package', data, controller)
            .then(response => {

                const {data, error, message} = response.data;

                setList(data)

                if (error) {
                    setError(message)
                } else {
                    setSuccess(message)

                    if (callback) {
                        setTimeout(() => callback(data), 500)
                    }
                }

                //console.log('orders#store', message)
            })
            .catch(error => {
                console.log('orders#error', error.data)
                setError(error.data)
            })
            .finally(() => isLoading(false))
    }

    const fetchData = () => {

        isLoading(true)

        return _get('/orders', controller)
            .then(response => {
                setList(response.data.data)

                console.log('orders', response.data.data)
            })
            .catch(error => {
                setError("Error Static")
            })
            .finally(() => isLoading(false))

    }

    useEffect(() => {

        fetchData()

        return () => controller.abort()

    }, [])

    const value = {
        list,
        success,
        error,
        loading,
        store

    };

    return (
        <OrderContext.Provider value={value}>
            {children}
        </OrderContext.Provider>
    );
}

export function useOrder() {
    return useContext(OrderContext);
}
