import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

export default function Unauthorised() {
    const text = 'Cette fonctionalité n\'est pas autorisée'
    return (
        <div className='text-center my-2 p-2 rounded'>
            <img src='/loop.png' alt={text} width={150} className='mb-2' />

            <div className='text-muted'>{text}</div>
        </div>
    )
}
