import {useEffect, useMemo, useRef, useState} from "react";

// Styles
import "./QrStyles.css";

// Qr Scanner
import QrScanner from "qr-scanner";
// @ts-ignore
import QrFrame from "../assets/qr-frame.svg";

const QrReader = ({onResult}: {onResult?: (data: any) => void}) => {

  // QR States
  const scanner = useRef<QrScanner>();

  const videoEl = useRef<HTMLVideoElement>(null);
  const qrBoxEl = useRef<HTMLDivElement>(null);

  const [qrOn, setQrOn] = useState<boolean>(true);
  const [success, setSuccess] = useState<boolean>(true);
  const [collected, setCollected] = useState<Set<string>>(new Set());

  //console.log('collected', collected);

  const addItem = (item: string) => {

      setCollected(prevSet => {

          const newSet = new Set(prevSet);

          newSet.add(item);

          if (onResult) {
              onResult(Array.from(newSet))
          }

          return newSet;
      });
  }



  // Success
  const onScanSuccess = (result: QrScanner.ScanResult) => {
    // 🖨 Print the "result" to browser console.

    // ✅ Handle success.
    addItem(result?.data);
    setSuccess(true);
  };

  // Fail
  const onScanFail = (err: string | Error) => {
    // 🖨 Print the "err" to browser console.
    setSuccess(false)
  };

  useEffect(() => {

    if (videoEl?.current && !scanner.current) {
      // 👉 Instantiate the QR Scanner
      scanner.current = new QrScanner(videoEl?.current, onScanSuccess, {
        onDecodeError: onScanFail,
        // 📷 This is the camera facing mode. In mobile devices, "environment" means back camera and "user" means front camera.
        preferredCamera: "environment",
        // 🖼 This will help us position our "QrFrame.svg" so that user can only scan when qr code is put in between our QrFrame.svg.
        highlightScanRegion: true,
        // 🔥 This will produce a yellow (default color) outline around the qr code that we scan, showing a proof that our qr-scanner is scanning that qr code.
        highlightCodeOutline: true,
        // 📦 A custom div which will pair with "highlightScanRegion" option above 👆. This gives us full control over our scan region.
        overlay: qrBoxEl?.current || undefined,
      });

      // 🚀 Start QR Scanner
      scanner?.current
        ?.start()
        .then(() => setQrOn(true))
        .catch((err) => {
          if (err) setQrOn(false);
        });
    }

    // 🧹 Clean up on unmount.
    // 🚨 This removes the QR Scanner from rendering and using camera when it is closed or removed from the UI.
    return () => {
      if (!videoEl?.current) {
        scanner?.current?.stop();
      }
    };
  }, []);

  // ❌ If "camera" is not allowed in browser permissions, show an alert.
  useEffect(() => {
    if (!qrOn)
      alert(
        "Camera is blocked or not accessible. Please allow camera in your browser permissions and Reload."
      );
  }, [qrOn]);

  return (
    <div className="qr-reader bg-dark">
      {/* QR */}
      <video ref={videoEl}></video>
      <div ref={qrBoxEl} className="qr-box">
        <img
          src={QrFrame}
          alt="Qr Frame"
          width={256}
          height={256}
          className="qr-frame"
        />
      </div>

      {/* Show Data Result if scan is success */}
      <Status success={success} />
    </div>
  );
};
function Status({success}: {success: boolean}) {

  const color = success ? 'green': 'red'
  return (
      <div
          style={{
            position: "absolute",
            top: 10,
            right: 10,
            zIndex: 999990,
            color: color,
            width: 30,
            height: 30,
            backgroundColor: color,
            borderRadius: 100
          }}
      ></div>
  )
}

export default QrReader;
