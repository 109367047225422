import * as React from "react";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";

export default function Welcome(user: any) {

    if (user === undefined || user.id === undefined) {
        return null
    }

    return (
        <div className='text-muted d-flex align-items-center justify-content-start'>
            {/*@ts-ignore*/}
            <FontAwesomeIcon className="fa-fw text-info" icon={faUser} />
            <strong className='px-2'>{user.first_name}</strong>
        </div>
    )
}
