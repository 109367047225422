import {SimpleLayout} from "../layouts"
import {useState, useEffect} from "react";
import {Loader, RequiredInfo, Unauthorised} from "../components";
import {_get} from "../api";
import {useParams} from "react-router-dom";
import {ContractType} from "../type-def";
import * as React from "react";
import {useApp} from "../contexts/appContext";
import {ContractPaymentForm, PaymentList} from "../domains/contracts";

export default function PaymentContractScreen() {

    const controller = new AbortController()
    const [loading, isLoading] = useState<boolean>(true)
    const [contract, setContract] = useState<ContractType>()
    const {slug} = useParams();

    const {can} = useApp();

    const reset = (contract: ContractType) => {
      fetchData()
    }


    const fetchData = () => {

        isLoading(true)

        _get(`/contracts/${slug}`)
            .then(response => {
                const contract = response.data.data
                //console.log('_contract', contract)
                if (contract) {
                    setContract(contract)
                }

            }).finally(() => isLoading(false))
    }

    useEffect(() => {
        fetchData()

        return () => controller.abort()
    }, [])

    if (! can('manage contracts')) {
        return <Unauthorised />
    }

    if (loading) {
        return <Loader />
    }

    return (
        <SimpleLayout goBack={'/'} title={contract?.title}>
            <RequiredInfo />
            {contract !== undefined && (
                <>
                    <ContractPaymentForm {...contract} onPaymentAdded={reset}  />
                    <PaymentList list={contract.payments} />
                </>
            )}
        </SimpleLayout>
    );
}
